
.addon-card
  @apply border-b-1 border-grey-lighter
  @screen md
    @apply border-none
  &__element__datepicker
    cursor: pointer
    padding:.75rem
    min-width: 9.5rem
    margin-right: 1rem
    border-radius:.375rem
    @apply bg-grey-lightest
    color: #686e74
  &__block
    &__sold-out
      right: 0
      top: 1px
    &__head
      &__gallery
        height: 375px
        @apply border-1 flex-no-shrink w-full
        @screen md
          width: 400px
        @screen lg
          @apply w-full
        @screen xl
          width: 400px
        .cover
          &:hover .zoomMask
            @apply opacity-100
            i
              @apply opacity-100
          .zoomMask
            @apply w-full h-full flex items-center justify-center opacity-0 cursor-pointer
            transition: opacity 0.3s ease-out
            background: radial-gradient(ellipse at center, rgba(0,0,0,0.20) 0%,rgba(0,0,0,0.55) 100%)
            i
              @apply text-white text-6xl opacity-0
              transition: opacity 0.3s ease-out
      &__selections
        &__row
          // @apply flex
          label
            @apply text-grey-dark font-medium mb-8
          span:not(.os-tag)
            @apply text-grey-darkest

          .vue-numeric-input
            @apply mt-0 #{!important}
            &.w-half
              @apply mb-16 #{!important}
              @screen sm
                @apply w-1/2 mb-0 #{!important}
          .addon-btn
            height: 38px
            @apply py-0

  &.is-sold-out
    pointer-events: none
    opacity: .6
.selfHostTitle
  @apply relative text-grey-darker
  &:after
    content: ''
    @apply absolute border-b-2 border-green
    width: 100%
    left: 0
    bottom: -1px
